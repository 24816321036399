import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _4485a164 = () => interopDefault(import('../pages/hospital-solutions.vue' /* webpackChunkName: "hospital-solutions" */))
const _27e230bc = () => interopDefault(import('../pages/teltonika-telemedic.com-landing.vue' /* webpackChunkName: "teltonika-telemedic.com-landing" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _65f69e04 = () => interopDefault(import('../pages/productsTelemedic/index.vue' /* webpackChunkName: "productsTelemedic" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _728de90a = () => interopDefault(import('../pages/medical-watches.vue' /* webpackChunkName: "medical-watches" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "null" */))
const _81177ef6 = () => interopDefault(import('../pages/telemedicine-platform.vue' /* webpackChunkName: "telemedicine-platform" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _471ad3d4 = () => interopDefault(import('../pages/eol-products.vue' /* webpackChunkName: "eol-products" */))
const _f4e2d386 = () => interopDefault(import('../pages/warranty-and-repair.vue' /* webpackChunkName: "warranty-and-repair" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))
const _643ffcbc = () => interopDefault(import('../pages/productsTelemedic/_slug.vue' /* webpackChunkName: "productsTelemedic" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___en"
  }, {
    path: "/hospital-solutions",
    component: _4485a164,
    meta: {"paths":{"en":"\u002Fhospital-solutions","lt":"\u002Fhospital-solutions"},"page_id":51},
    name: "hospital-solutions-51___en"
  }, {
    path: "/industries",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","lt":"\u002Findustrija"},"page_id":59},
    name: "industries-59___en"
  }, {
    path: "/lt",
    component: _27e230bc,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":5},
    name: "teltonika-telemedic.com-landing-5___lt"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/products",
    component: _65f69e04,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai"},"page_id":53},
    name: "products-53___en"
  }, {
    path: "/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper"},"page_id":54},
    name: "products-wrapper-54___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/smart-medical-solutions",
    component: _728de90a,
    meta: {"paths":{"en":"\u002Fsmart-medical-solutions","lt":"\u002Fsmart-medical-solutions"},"page_id":50},
    name: "smart-medical-solutions-50___en"
  }, {
    path: "/solutions",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsolutions","lt":"\u002Fsolutions"},"page_id":77},
    name: "solutions-77___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":78},
    name: "subscribe-78___en"
  }, {
    path: "/subscribe-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___en"
  }, {
    path: "/support",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","lt":"\u002Faptarnavimas"},"page_id":76},
    name: "support-76___en"
  }, {
    path: "/telecare-2",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelecare-2","lt":"\u002Ftelecare-2"},"page_id":79},
    name: "telecare-2-79___en"
  }, {
    path: "/telecare-3",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelecare-3","lt":"\u002Ftelecare-3"},"page_id":85},
    name: "telecare-3-85___en"
  }, {
    path: "/telemedicine-platform",
    component: _81177ef6,
    meta: {"paths":{"en":"\u002Ftelemedicine-platform","lt":"\u002Ftelemedicine-platform"},"page_id":52},
    name: "telemedicine-platform-52___en"
  }, {
    path: "/telto-watch",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelto-watch","lt":"\u002Ftelto-watch"},"page_id":55},
    name: "telto-watch-55___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fcareer","lt":"\u002Fkarjera"},"page_id":21},
    name: "career-21___lt"
  }, {
    path: "/lt/hospital-solutions",
    component: _4485a164,
    meta: {"paths":{"en":"\u002Fhospital-solutions","lt":"\u002Fhospital-solutions"},"page_id":51},
    name: "hospital-solutions-51___lt"
  }, {
    path: "/lt/industrija",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Findustries","lt":"\u002Findustrija"},"page_id":59},
    name: "industries-59___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/produktai",
    component: _65f69e04,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai"},"page_id":53},
    name: "products-53___lt"
  }, {
    path: "/lt/produktai-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper"},"page_id":54},
    name: "products-wrapper-54___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/smart-medical-solutions",
    component: _728de90a,
    meta: {"paths":{"en":"\u002Fsmart-medical-solutions","lt":"\u002Fsmart-medical-solutions"},"page_id":50},
    name: "smart-medical-solutions-50___lt"
  }, {
    path: "/lt/solutions",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsolutions","lt":"\u002Fsolutions"},"page_id":77},
    name: "solutions-77___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok"},"page_id":78},
    name: "subscribe-78___lt"
  }, {
    path: "/lt/prenumeruoti-old",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fsubscribe-old","lt":"\u002Fprenumeruoti-old"},"page_id":13},
    name: "subscribe-old-13___lt"
  }, {
    path: "/lt/aptarnavimas",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fsupport","lt":"\u002Faptarnavimas"},"page_id":76},
    name: "support-76___lt"
  }, {
    path: "/lt/telecare-2",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelecare-2","lt":"\u002Ftelecare-2"},"page_id":79},
    name: "telecare-2-79___lt"
  }, {
    path: "/lt/telecare-3",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelecare-3","lt":"\u002Ftelecare-3"},"page_id":85},
    name: "telecare-3-85___lt"
  }, {
    path: "/lt/telemedicine-platform",
    component: _81177ef6,
    meta: {"paths":{"en":"\u002Ftelemedicine-platform","lt":"\u002Ftelemedicine-platform"},"page_id":52},
    name: "telemedicine-platform-52___lt"
  }, {
    path: "/lt/telto-watch",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftelto-watch","lt":"\u002Ftelto-watch"},"page_id":55},
    name: "telto-watch-55___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/support/eol-products",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga"},"page_id":200},
    name: "support-eol-products-200___en"
  }, {
    path: "/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas"},"page_id":75},
    name: "support-warranty-repair-75___en"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/aptarnavimas/produktu-gyvavimo-ciklo-pabaiga",
    component: _471ad3d4,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga"},"page_id":200},
    name: "support-eol-products-200___lt"
  }, {
    path: "/lt/aptarnavimas/garantija-ir-remontas",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas"},"page_id":75},
    name: "support-warranty-repair-75___lt"
  }, {
    path: "/",
    component: _27e230bc,
    meta: {"paths":{"en":"\u002F","lt":"\u002F"},"page_id":5},
    name: "teltonika-telemedic.com-landing-5___en"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/lt/produktai/:slug",
    component: _643ffcbc,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug"},"page_id":53},
    name: "products-:slug-53-slug___lt"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }, {
    path: "/products/:slug",
    component: _643ffcbc,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug"},"page_id":53},
    name: "products-:slug-53-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
