import { latestProducts } from '~/queries/latestProducts.gql'
import { getUrlFromJson } from '~/utils'
export default {
  async fetch() {
    try {
      const {
        data: { products },
      } = await this.$gqlQuery({
        query: latestProducts,
        fetchPolicy: 'no-cache',
        variables: {
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
          limit: 3,
          tags: Array.isArray(this.productTag)
            ? this.productTag
            : [this.productTag ?? 'telecare'],
        },
      })
      this.productsList = products?.data.map((product) => {
        return (
          {
            id: product.id,
            title: product.ext_sections?.[0]?.groups?.card?.title || '',
            image: product.ext_sections?.[0]?.groups?.card?.image || 's',
            link: getUrlFromJson(product.urls),
            description: product.ext_sections?.[0]?.groups?.card?.content || '',
            tags: product.tags,
          } || []
        )
      })
    } catch (ex) {
      console.error(ex)
      this.productsList = []
    }
  },
  data() {
    return {
      productsList: [],
    }
  },
}
